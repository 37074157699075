<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.statDateDay"
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        excel导出
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    label="订单信息"
                >
                    <el-table-column
                        prop="orderSn"
                        label="订单编号"
                        width="120"
                    />
                    <el-table-column
                        label="下单时间"
                        width="85"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="下单人"
                        width="100"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.mobile }}
                            <div v-if="scope.row.distributorName">
                                {{ scope.row.distributorName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="下单人级别"
                        width="85"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.isDistributor ? scope.row.distributorLevelName:scope.row.buyerLevelName }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="店铺名称"
                        width="80"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.storeName ? scope.row.storeName:'平台' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsSendModeName"
                        label="发货方式"
                        width="80"
                    />
                    <el-table-column
                        prop="deliveryConsignee"
                        label="收件人"
                        width="80"
                    />
                    <el-table-column
                        prop="deliveryPhone"
                        label="收件人电话"
                        width="100"
                    />
                    <el-table-column
                        label="收件人地址"
                        width="120"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.deliveryRegionName }} {{ scope.row.deliveryAddress }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="orderCompletionDays"
                        label="订单时效(天)"
                        width="90"
                    />
                </el-table-column>
                <el-table-column
                    label="订单收款"
                >
                    <el-table-column
                        prop="payableAmount"
                        label="订单总金额"
                        width="85"
                    />
                    <el-table-column
                        prop="goodsAmount"
                        label="商品金额"
                        width="80"
                    />
                    <el-table-column
                        prop="freight"
                        label="运费"
                        width="60"
                    />
                    <el-table-column
                        prop="paymentPoundage"
                        label="付款手续费"
                        width="85"
                    />
                </el-table-column>
                <el-table-column
                    label="采购成本"
                >
                    <el-table-column
                        prop="orderGoodsCostAmount"
                        label="采购成本"
                        width="80"
                    />
                    <el-table-column
                        prop="orderCostGstAmount"
                        label="其中GST"
                        width="80"
                    />
                </el-table-column>
                <el-table-column
                    label="支付工具成本"
                >
                    <el-table-column
                        prop="paymentPoundageCost"
                        label="支付手续费"
                        width="100"
                    />
                </el-table-column>
                <el-table-column
                    label="佣金信息"
                >
                    <el-table-column
                        label="佣金总金额"
                        width="85"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.agentCommisionAmount + scope.row.distributionCommisionAmount }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="distributionCommisionAmount"
                        label="分销佣金"
                        width="80"
                    />
                    <el-table-column
                        prop="agentCommisionAmount"
                        label="店铺佣金"
                        width="80"
                    />
                </el-table-column>
                <el-table-column
                    label="物流成本"
                >
                    <el-table-column
                        prop="trackingTotalAmount"
                        label="物流总成本"
                        width="85"
                    />
                    <el-table-column
                        prop="packageWeight"
                        label="包裹重量"
                        width="80"
                    />
                    <el-table-column
                        prop="freightAmount"
                        label="物流运费"
                        width="80"
                    />
                    <el-table-column
                        prop="taxAmount"
                        label="物流税金"
                        width="80"
                    />
                    <el-table-column
                        prop="materialAmount"
                        label="耗材费用"
                        width="80"
                    />
                    <el-table-column
                        prop="otherAmount"
                        label="物流其他费用"
                        width="95"
                    />
                </el-table-column>
                <el-table-column
                    label="利润"
                >
                    <el-table-column
                        prop="goodsProfitGST"
                        label="商品利润"
                        width="80"
                    />
                    <el-table-column
                        prop="goodsProfit"
                        label="商品未退GST利润"
                        width="120"
                    />
                    <el-table-column
                        prop="paymentPoundageProfit"
                        label="付款手续费利润"
                        width="110"
                    />
                    <el-table-column
                        prop="freightProfit"
                        label="物流利润"
                        width="80"
                    />
                    <el-table-column
                        prop="totalProfitGst"
                        label="利润合计"
                        width="80"
                    />
                    <el-table-column
                        prop="totalProfit"
                        label="未退GST利润合计"
                        width="120"
                    />
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'OrderStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSn: '',
                statDateDay: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.OrderStat.orderStatList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        // excel导出
        exportExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let statBeginDate = null;
            let statEndDate = null;
            if (this.queryFormModel.statDateDay != null && this.queryFormModel.statDateDay.length === 2) {
                [statBeginDate, statEndDate] = this.queryFormModel.statDateDay;
            }
            this.$http.href('/stat/orderStat/exportExcel', {
                ...this.queryFormModel,
                statBeginDate,
                statEndDate,
                ids,
            });
        },
    },
};
</script>

<style lang="scss">
</style>
