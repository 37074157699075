<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderTime"
                    label="下单时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.orderTime"
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentTime"
                    label="付款时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.paymentTime"
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        excel导出
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="orderSn"
                    label="订单编号"
                    width="150"
                />
                <el-table-column
                    label="下单时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="quantity"
                    label="数量"
                    width="60"
                />
                <el-table-column
                    prop="finalPrice"
                    label="单价"
                    width="60"
                />
                <el-table-column
                    prop="subtotalAmount"
                    label="销售额"
                    width="60"
                />
                <el-table-column
                    label="付款时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="paymentAmount"
                    label="实付金额"
                    width="70"
                />
                <el-table-column
                    prop="serviceCharge"
                    label="手续费"
                    width="60"
                />
                <el-table-column
                    prop="refundAmount"
                    label="退款金额"
                    width="70"
                />
                <el-table-column
                    prop="amount"
                    label="实收金额"
                    width="70"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'OrderStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSn: '',
                goodsName: '',
                orderTime: [],
                paymentTime: [],
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.OrderStat.orderGoodsStatList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        // excel导出
        exportExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let orderTimeBegin = null;
            let orderTimeEnd = null;
            if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                [orderTimeBegin, orderTimeEnd] = this.queryFormModel.orderTime;
            }
            let paymentTimeBegin = null;
            let paymentTimeEnd = null;
            if (this.queryFormModel.paymentTime != null && this.queryFormModel.paymentTime.length === 2) {
                [paymentTimeBegin, paymentTimeEnd] = this.queryFormModel.paymentTime;
            }
            this.$http.href('/stat/orderStat/exportOrderGoodsStatExcel', {
                ...this.queryFormModel,
                orderTimeBegin,
                orderTimeEnd,
                paymentTimeBegin,
                paymentTimeEnd,
                ids,
            });
        },
    },
};
</script>

<style lang="scss">
</style>
